import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

const PRIVATE_ROUTE_PREFIX_BACKWARD = "app/";
export const LANDING_ORG_ROUTE = `${PRIVATE_ROUTE_PREFIX_BACKWARD}landing-organization`;
export const FARM_ROUTE = "property";
export const SEASON_ROUTE = "season";
const PRIVATE_ROUTE_PREFIX_FORWARD = "/app";

export const URL_CONSTANTS = {
  ROOT: () => "/",
  LOGIN: (email?: string) => `/signin${email ? "?email=" + email : ""}`,
  SIGNUP: () => "/signup",
  SIGNUP_PASSWORD: () => "/signup-password",
  SIGNUP_AUTH: () => "/auth",
  SIGNUP_LOCAL: (email?: string) => `/signup-local${ email ? "?userEmail=" + email : ""}`,
  SIGNUP_SOCIAL_STEP: () => "/signup-social-step",
  DISTRIBUTION_SIGNIN: (distributionData: string) => `/distribution-link?distribution_data=${distributionData}`,
  RECOVERY_PASSWORD: () => "/recovery-password",
  RESET_PASSWORD: () => "/reset-password",
  REVIEW_PAGE: () => "/review-page",
  HALL_OF_ORG: () => `${PRIVATE_ROUTE_PREFIX_FORWARD}/hall-of-org`,
  SIGN_LEGAL_DOCUMENTS: () => "/sign-legal-documents",
  ACCOUNT_CREATED: () => "/account-created",
  VOUCHER_REDEEM: () => "/voucher-redeem",
  NAMING_WORKSPACE: () => "/naming-workspace",
  PRODUCT_OVERVIEW: () => "/product-overview",
  AUTHENTICATE: () => "/authenticate",
  NEW_ACCOUNT_VERIFICATION: () => "/verification",
  OAUTH_IDP_AUTHORIZATION: (
    idpName: string = ":idpName",
    urlDomain: string = ":urlDomain"
  ) =>
    `/oauth2/cw/${idpName}${
      urlDomain !== ":urlDomain" ? urlDomain : ""
    }`,
  OAUTH_IDP_AUTHORIZATION_SIGNUP: (
    idpName: string = ":idpName",
    urlDomain: string = ":urlDomain",
    state?: string
  ) =>
    `/oauth2/cw/${idpName}${
      urlDomain !== ":urlDomain" ? urlDomain : ""
    }${"?signup=true"}${state ? "&state=" + state : ""}`
};

export const publicItems = [
  "/",
  "/signin",
  "/sign-up-1",
  "/signup",
  "/reset-password",
  "/recovery-password",
  "/account-created",
  "/voucher-redeem",
  "/naming-workspace",
  "/product-overview",
  "/v2/accounts/email/verify",
  URL_CONSTANTS.OAUTH_IDP_AUTHORIZATION()
];
